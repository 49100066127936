<style scoped>
	.container-trust-meter {
		width: 100px;
		border: 1px solid #E0E0E0;
		padding: 10px;
		border-radius: 20px;
		margin-right: 10px;
		display: flex;
		justify-content: start;
		align-items: center;
		cursor: pointer;
	}
	.icon-trust-meter {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		margin-right: 5px;
	}
	.text-trust-meter {
		font-weight: bold;
	}
	.vue-simple-suggest.designed .suggestions .suggest-item {
		padding: 0 !important;
	}
</style>
<template>
	<div>
		<vue-element-loading :active="isLoadingTable" spinner="bar-fade-scale" color="#FF6700" />
		<div class="row">
			<div class="col-md-12 col-lg-6">
				<button v-can="'CONTACT_MANAGEMENT_CREATE'" class="btn btn-create" @click="showFormInput"><i class="mdi mdi-plus-circle mr-1"></i> Create New</button>
			</div>
			<div class="col-sm-12 col-md-12 col-lg-6">
				<div class="float-lg-right float-left">
					<div class="form-inline">
						<label class="mb-2 mr-sm-2">Search :</label>
						<input v-model="filterText" type="text" class="form-control form-control-sm mb-2 mr-sm-2" placeholder="Search . . ." @keyup="doFilter" />
					</div>
				</div>
			</div>
		</div>
		<!-- form modal input-->
		<modal name="inputCustomer" height="auto" :click-to-close="formClose">
			<div class="row justify-content-center">
				<div class="col-md-12">
					<div class="card">
						<div class="card-body" style="max-height: 500px; overflow: auto;">
							<h1 class="header-title"> Form Input Customer </h1>

							<form @submit.prevent="inputData">
								<div class="form-row mt-3">

									<div class="col-md-6">
										<vue-simple-suggest v-model="dataNewCustomer.name" :styles="autoCompleteStyle" display-attribute="name" value-attribute="id" :list="getCustomerList" :filter-by-query="true" placeholder="Name*" required @select="onSuggestSelect">
											<!-- Filter by input text to only show the matching results -->
											<div slot="suggestion-item" slot-scope="{ suggestion }" style="padding: 0;">
												<div v-on:click.stop="onAutocompleteButtonClick(suggestion)">
													<span>{{ suggestion.name }}</span>
													<div v-on:click.stop="onAutocompleteButtonClick(suggestion)" v-show="suggestion.customer_history.trust_meter === '2'">
														<div v-b-tooltip.hover :title="suggestion.customer_history ? suggestion.customer_history.remark : ''" style="width: 70px;background: #EA3131;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
															<div>
																<span>BAD</span>
															</div>
															<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
														</div>
													</div>
													<div v-on:click.stop="onAutocompleteButtonClick(suggestion)" v-show="suggestion.customer_history.trust_meter === '4'">
														<div v-b-tooltip.hover :title="suggestion.customer_history ? suggestion.customer_history.remark : ''" style="width: 80px;background: #41A31F;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
															<div>
																<span>GOOD</span>
															</div>
															<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
														</div>
													</div>
													<div v-on:click.stop="onAutocompleteButtonClick(suggestion)" v-show="suggestion.customer_history.trust_meter === '3'">
														<div v-b-tooltip.hover :title="suggestion.customer_history ? suggestion.customer_history.remark : ''" style="width: 115px;background: #F9BF2C;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
															<div>
																<span>MODERATE</span>
															</div>
															<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
														</div>
													</div>
													<div v-on:click.stop="onAutocompleteButtonClick(suggestion)" v-show="suggestion.customer_history.trust_meter === '1'">
														<div v-b-tooltip.hover :title="suggestion.customer_history ? suggestion.customer_history.remark : ''" style="width: 115px;background: #808080;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
															<div>
																<span>UNKNOWN</span>
															</div>
															<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
														</div>
													</div>
												</div>
											</div>
										</vue-simple-suggest>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<input v-model="dataNewCustomer.email" type="email" class="form-control form-control-sm" placeholder="Email*" required />
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<input v-model="dataNewCustomer.name_pic" type="text" class="form-control form-control-sm" placeholder="PIC Name*" required />
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<input v-model="dataNewCustomer.mobile" type="number" class="form-control form-control-sm" placeholder="Mobile Phone*" required />
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<input v-model="dataNewCustomer.position" type="text" class="form-control form-control-sm" placeholder="PIC Position" />
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<input v-model="dataNewCustomer.phone" type="number" class="form-control form-control-sm" placeholder="Office Phone*" required />
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<textarea v-model="dataNewCustomer.address" rows="4" placeholder="Address*" class="form-control form-control-sm" required></textarea>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<textarea v-model="dataNewCustomer.remarks" rows="4" placeholder="Remarks" class="form-control form-control-sm"></textarea>
										</div>
									</div>
									<!-- <div class="form-row mt-3"> -->
										<div class="col-md-12">
											<span style="font-size: 16px;font-weight: 700;color: #343A40;">Customer Trust Meter</span>
										</div>
										<div class="col-md-12 mt-2" style="display: flex;">
											<div class="container-trust-meter" @click="changeTrustMeter('0')" :style="{ background: this.trustMeter !== '0' ? '#FFF' : '#86868633' }">
												<div :class="'icon-trust-meter'" style="display: flex;align-items: center;justify-content: center;">
													<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="7" cy="7.5" r="6.75" stroke="#868686" stroke-width="0.5"/>
														<line x1="12.0303" y1="2.78033" x2="2.53033" y2="12.2803" stroke="#868686" stroke-width="1.5"/>
													</svg>
												</div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '0' ? '#868686' : '#868686' }">
													<span>Not Set</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 110px;" @click="changeTrustMeter('1')" :style="{ background: this.trustMeter !== '1' ? '#FFF' : '#808080' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '1' ? '#FFF' : '#808080' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '1' ? '#FFF' : '#808080' }">
													<span>Unknown</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 80px;" @click="changeTrustMeter('2')" :style="{ background: this.trustMeter !== '2' ? '#FFF' : '#EA3131' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '2' ? '#FFF' : '#EA3131' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '2' ? '#FFF' : '#EA3131' }">
													<span>Bad</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 110px;" @click="changeTrustMeter('3')" :style="{ background: this.trustMeter !== '3' ? '#FFF' : '#F9BF2B' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '3' ? '#FFF' : '#F9BF2B' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '3' ? '#FFF' : '#F9BF2B' }">
													<span>Moderate</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 85px;" @click="changeTrustMeter('4')" :style="{ background: this.trustMeter !== '4' ? '#FFF' : '#41A21F' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '4' ? '#FFF' : '#41A21F' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '4' ? '#FFF' : '#41A21F' }">
													<span>Good</span>
												</div>
											</div>
										</div>
										<div class="col-md-12 mt-2">
											<div class="form-group">
												<label for="">Notes</label>
												<input v-model="komentar" type="text" class="form-control form-control-sm" required />
											</div>
										</div>
										<div class="col-md-12">
											<div class="form-group">
												<label for="">Assignee<span class="text-danger" aria-required="true"> * </span></label>
												<v-select multiple="true" v-model="assignee" :options="listUser" :reduce="(option) => option.id" label="name_user" >
												</v-select>
											</div>
										</div>
									<!-- </div> -->
									<hr />
									<div class="col-md-12 mt-1">
										<div class="float-right">
											<input type="submit" class="btn btn-create mr-1 btn-sm" value="Save" />
											<button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideFormInput">Cancel</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</modal>
		<!-- form modal schedule-->
		<modal :click-to-close="formClose" name="scheduleCustomer" height="auto">
			<div class="row justify-content-center">
				<div class="col-md-12">
					<div class="card">
						<VuePerfectScrollbar style="max-height: 500px">
							<div class="card-body">
								<h1 class="header-title"> Form Schedule customer </h1>
								<form @submit.prevent="submitData">
									<div class="form-row mt-3">
										<div class="col-md-12">
											<div class="form-group">
												<label for="">Customer</label>
												<input v-model="dataCustomer.name" type="text" class="form-control form-control-sm" disabled />
											</div>
											<div class="form-group">
												<label for="">Choose a Status</label>
												<!-- <select v-model="dataCustomer.status" class="form-control form-control-sm" required>
													<option v-for="(value,key) in listStatus" :key="key" :value="value.id">
														{{value.name}}</option>
												</select> -->
												<v-select v-model="status" :options="listStatus" label="name" @input="changeStatus">
												</v-select>
											</div>
										</div>
										<div v-if="dataCustomer.status === 1" class="col-md-12">
											<div class="form-group">
												<label for="">Schedule Time <span class="text-danger">*</span>
												</label>
												<datetime v-model="dataCustomer.schedule_time" format="YYYY-MM-DD h:i:s" required></datetime>
											</div>
										</div>
										<div v-if="status === 'Reject'" class="col-md-12 mt">
											<div class="form-group">
												<label for="">Choose a Reason</label>
												<v-select v-model="reason" :options="listReason" label="reason" @input="changeReason" >
													<template v-slot:search="{ attributes, events }">
														<input
														class="vs__search"
														:required="!reason"
														v-bind="attributes"
														v-on="events"
														/>
													</template>
												</v-select>
											</div>
										</div>
										<div class="col-md-12">
											<div class="form-group">
												<label for="">Notes</label>
												<input v-model="remark" type="text" class="form-control form-control-sm" required />
											</div>
										</div>
										<div class="col-md-12 mt-1">
											<div class="float-right">
												<input type="submit" class="btn btn-create btn-sm mr-1" value="Save" />
												<button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideFormSchedule">Cancel</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</VuePerfectScrollbar>
					</div>
				</div>
			</div>
		</modal>

		<!-- form modal Komentar-->
		<modal :click-to-close="formClose" name="commentCustomer" height="auto">
			<div class="row justify-content-center">
				<div class="col-md-12">
					<div class="card">
						<VuePerfectScrollbar>
							<div v-can="'CONTACT_MANAGEMENT_SCHEDULE_ENABLE'" class="card-body">
								<h1 class="header-title"> Add Notes </h1>
								<form @submit.prevent="addCommentar">
									<div class="form-row mt-3">
										<div class="col-md-12">
											<span style="font-size: 16px;font-weight: 700;color: #343A40;">Customer Trust Meter</span>
										</div>
										<div class="col-md-12 mt-2" style="display: flex;">
											<div class="container-trust-meter" @click="changeTrustMeter('0')" :style="{ background: this.trustMeter !== '0' ? '#FFF' : '#86868633' }">
												<div :class="'icon-trust-meter'" style="display: flex;align-items: center;justify-content: center;">
													<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="7" cy="7.5" r="6.75" stroke="#868686" stroke-width="0.5"/>
														<line x1="12.0303" y1="2.78033" x2="2.53033" y2="12.2803" stroke="#868686" stroke-width="1.5"/>
													</svg>
												</div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '0' ? '#868686' : '#868686' }">
													<span>Not Set</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 110px;" @click="changeTrustMeter('1')" :style="{ background: this.trustMeter !== '1' ? '#FFF' : '#808080' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '1' ? '#FFF' : '#808080' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '1' ? '#FFF' : '#808080' }">
													<span>Unknown</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 80px;" @click="changeTrustMeter('2')" :style="{ background: this.trustMeter !== '2' ? '#FFF' : '#EA3131' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '2' ? '#FFF' : '#EA3131' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '2' ? '#FFF' : '#EA3131' }">
													<span>Bad</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 110px;" @click="changeTrustMeter('3')" :style="{ background: this.trustMeter !== '3' ? '#FFF' : '#F9BF2B' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '3' ? '#FFF' : '#F9BF2B' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '3' ? '#FFF' : '#F9BF2B' }">
													<span>Moderate</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 85px;" @click="changeTrustMeter('4')" :style="{ background: this.trustMeter !== '4' ? '#FFF' : '#41A21F' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '4' ? '#FFF' : '#41A21F' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '4' ? '#FFF' : '#41A21F' }">
													<span>Good</span>
												</div>
											</div>
										</div>
										<div class="col-md-12 mt-2">
											<div class="form-group">
												<label for="">Notes</label>
												<input v-model="komentar" type="text" class="form-control form-control-sm" required />
											</div>
										</div>
										<div class="col-md-12 mt-1">
											<div class="float-right">
												<input type="submit" class="btn btn-create mr-1 btn-sm" value="Save" />
												<button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideFormKomentar">Cancel
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>

							<div v-can-check="'CONTACT_MANAGEMENT_SCHEDULE_ENABLE'" class="card-body">
								<h1 class="header-title"> Information</h1>
								<form @submit.prevent="submitData">
									<div class="form-row mt-3">
										<div class="col-md-12">
											You cannot edit this data because you not have access
										</div>
										<div class="col-md-12 mt-1">
											<div class="float-right">
												<button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideFormKomentar">Cancel
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</VuePerfectScrollbar>
					</div>
				</div>
			</div>
		</modal>

		<!-- form modal edit-->
		<modal :click-to-close="formClose" name="editCustomer" height="auto">
			<div class="row justify-content-center">
				<div class="col-md-12">
					<div class="card">
						<VuePerfectScrollbar style="max-height: 500px">
							<div class="card-body">
								<h1 class="header-title"> Form edit customer </h1>
								<form @submit.prevent="updateDate">
									<div class="form-row mt-3">
										<div class="col-md-6">
											<div class="form-group">
												<label for="">Company Name*</label>
												<input v-model="dataCustomer.name" type="text" class="form-control form-control-sm" />
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="">Email*</label>
												<input v-model="dataCustomer.email" type="email" class="form-control form-control-sm" placeholder="email" />
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="">PIC Name*</label>
												<input v-model="dataCustomer.pic_name" type="text" class="form-control form-control-sm" placeholder="PIC Name" required />
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="">Mobile Phone*</label>
												<input v-model="dataCustomer.mobile" type="number" class="form-control form-control-sm" placeholder="mobile" required />
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="">PIC Position</label>
												<input v-model="dataCustomer.position" type="text" class="form-control form-control-sm" placeholder="PIC Position" />
											</div>
										</div>

										<div class="col-md-6">
											<div class="form-group">
												<label for="">Office Phone*</label>
												<input v-model="dataCustomer.phone" type="number" class="form-control form-control-sm" placeholder="Office Phone" required />
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="">Address*</label>
												<textarea v-model="dataCustomer.address" rows="4" placeholder="address" class="form-control form-control-sm" required></textarea>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="">Remarks</label>
												<textarea v-model="dataCustomer.remarks" rows="4" placeholder="Remarks" class="form-control form-control-sm"></textarea>
											</div>
										</div>

										<div class="col-md-12">
											<span style="font-size: 16px;font-weight: 700;color: #343A40;">Customer Trust Meter</span>
										</div>
										<div class="col-md-12 mt-2" style="display: flex;">
											<div class="container-trust-meter" @click="changeTrustMeter('0')" :style="{ background: this.trustMeter !== '0' ? '#FFF' : '#86868633' }">
												<div :class="'icon-trust-meter'" style="display: flex;align-items: center;justify-content: center;">
													<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="7" cy="7.5" r="6.75" stroke="#868686" stroke-width="0.5"/>
														<line x1="12.0303" y1="2.78033" x2="2.53033" y2="12.2803" stroke="#868686" stroke-width="1.5"/>
													</svg>
												</div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '0' ? '#868686' : '#868686' }">
													<span>Not Set</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 110px;" @click="changeTrustMeter('1')" :style="{ background: this.trustMeter !== '1' ? '#FFF' : '#808080' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '1' ? '#FFF' : '#808080' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '1' ? '#FFF' : '#808080' }">
													<span>Unknown</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 80px;" @click="changeTrustMeter('2')" :style="{ background: this.trustMeter !== '2' ? '#FFF' : '#EA3131' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '2' ? '#FFF' : '#EA3131' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '2' ? '#FFF' : '#EA3131' }">
													<span>Bad</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 110px;" @click="changeTrustMeter('3')" :style="{ background: this.trustMeter !== '3' ? '#FFF' : '#F9BF2B' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '3' ? '#FFF' : '#F9BF2B' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '3' ? '#FFF' : '#F9BF2B' }">
													<span>Moderate</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 85px;" @click="changeTrustMeter('4')" :style="{ background: this.trustMeter !== '4' ? '#FFF' : '#41A21F' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '4' ? '#FFF' : '#41A21F' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '4' ? '#FFF' : '#41A21F' }">
													<span>Good</span>
												</div>
											</div>
										</div>
										<div class="col-md-12 mt-2">
											<div class="form-group">
												<label for="">Notes</label>
												<input v-model="komentar" type="text" class="form-control form-control-sm" required />
											</div>
										</div>

										<div class="col-md-12">
											<div class="form-group">
												<label for="">Assignee<span class="text-danger" aria-required="true"> * </span></label>
												<v-select multiple="true" v-model="assignee" :options="listUser" :reduce="(option) => option.id" label="name_user" >
												</v-select>
											</div>
										</div>

										<div class="col-md-12 mt-1">
											<div class="float-right">
												<input type="submit" class="btn btn-create btn-sm mr-1" value="Save" />
												<button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideFormEdit">Cancel
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</VuePerfectScrollbar>
					</div>
				</div>
			</div>
		</modal>

		<!-- Modal LOG detail -->
		<modal name="logCustomer" height="auto">
			<div class="row justify-content-center">
				<div class="col-md-12">
					<div class="card">
						<div class="card-body">
							<h1 class="header-title"> Detail Customer </h1>
							<div class="card border">
								<div class="card-body">
									<VuePerfectScrollbar style="max-height: 200px">
										<div class="row">
											<div style="margin-bottom: 20px" class="col-md-12 text-left">
												<h5>
													<img :src="
                              'https://ui-avatars.com/api/?name=' +
                              dataCustomer.name
                            " style="
                              width: 35px;
                              height: 35px;
                              border-radius: 8.25rem !important;
                            " />
													{{ dataCustomer.name }}</h5>
											</div>
											<div class="col-md-2">
												<span><b>PIC</b></span>
											</div>
											<div class="col-md-4">
												<span>: {{ dataCustomer.pic_name }}</span>
											</div>
											<div class="col-md-2">
												<span><b>Mail </b></span>
											</div>
											<div class="col-md-4">
												<span>: {{ dataCustomer.email }}</span>
											</div>

											<div class="col-md-2">
												<span><b>Mobile</b></span>
											</div>
											<div class="col-md-4">
												<span>: {{ dataCustomer.mobile }}</span>
											</div>
											<div class="col-md-2">
												<span><b>Office</b></span>
											</div>
											<div class="col-md-4">
												<span>: {{ dataCustomer.phone }}</span>
											</div>

											<div class="col-md-2">
												<span><b>Address</b></span>
											</div>
											<div class="col-md-10" style="white-space: pre-line">
												<span>: {{ dataCustomer.address }}</span>
											</div>
											<div class="col-md-2">
												<span><b>Remarks</b></span>
											</div>
											<div class="col-md-10" style="white-space: pre-line">
												<span>: {{ dataCustomer.remarks }}</span>
											</div>
										</div>
										<div class="row" style="margin-top: 30px">
											<div class="col-md-4">
												<span><b>Created By</b></span>
											</div>
											<div class="col-md-8">
												<span>: {{ dataCustomer.created_name }}</span>
											</div>
											<div class="col-md-4">
												<span><b>Date Created </b></span>
											</div>
											<div class="col-md-8">
												<span>: {{ dataCustomer.created_on }}</span>
											</div>
										</div>
									</VuePerfectScrollbar>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12 bg-light p-1"> LOG </div>
								<div class="col-md-12">
									<VuePerfectScrollbar style="max-height: 300px; max-width: 100%">
										<div class="row">
											
											<div v-for="item in dataCustomerHistory.history" :key=" item.id" class="col-md-12 mt-1">
												<span>
													<i class="fas fa-history"> </i><span class="text-info">
														{{ item.user.profile.name_user }}
													</span>
													changed status to
													<span v-if="item.status === '0'">'Leads'</span>
													<span v-else-if="item.status === '1'">'Visit'</span>
													<span v-else-if="item.status === '2'">'Approved'</span>
													<span v-else-if="item.status === '3'">'Prospect'</span>
													<span v-else-if="item.status === '4'">'Rejected'</span>
													on <b>{{ dataCustomer.name }}</b>
													<span class="text-muted" style="font-size: 11px">
														{{ format_date(item.created_at) }}</span>
													<br />
													<span>note : {{ item.remark }}</span>
													
													<span v-if="item.status === '1'">
														<br />
														Schedule time : {{ format_date(item.schedule_time) }}
													</span>
													
													<span v-if="item.status === '4'">
														<br>
														Reason : {{ isiReason }}
													</span>
													<hr />
												</span>
											</div>
											<div v-for="item in dataCustomerHistory.comment" :key=" item.id" class="col-md-12 mt-1">
												<!-- <br> -->
												<span>
													<i class="fas fa-comment mr-1"></i>
													<span class="text-info">{{ item.user.profile.name_user }}</span> comments :
													<span>
														{{item.remark}}
													</span>
													<span class="text-muted" style="font-size: 11px">
														{{ format_date(item.created_at) }}</span>

													
												</span>
											</div>
										</div>
									</VuePerfectScrollbar>
								</div>
								<!-- end col -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</modal>

		<!-- Table -->
		<div class="table-responsive">
			<vuetable ref="vuetable" :per-page="perPage" :fields="fields" :api-url="apiUrl" :api-mode="true" :http-options="httpOptions" :append-params="moreParams" pagination-path class="table table-centered table-striped" data-path="data" @vuetable:loading="loadingTable" @vuetable:load-success="loadTableSuccess" @vuetable:load-error="loadTableFailed" @vuetable:pagination-data="onPaginationData">
				<template slot="name" slot-scope="props">
					<a v-can="'CONTACT_MANAGEMENT_VIEW'" href="javascript:void(0);" @click="detailLog(props.rowData, props.rowIndex)">
						<!-- <i class="fas fa-eye"></i> -->
						{{props.rowData.name}}

					</a>
				</template>
				<template slot="created_at" slot-scope="props">
					{{format_date2(props.rowData.created_at)}}
				</template>
				<template slot="updated_at" slot-scope="props">
					{{format_date2(props.rowData.updated_on)}}
				</template>
				<template slot="actions" slot-scope="props">
					<div class="table-button-container">
						<a v-can="'CONTACT_MANAGEMENT_UPDATE'" href="javascript:void(0);" class="action-icon " @click="editShow(props.rowData, props.rowIndex)">
							<i class="fas fa-edit"></i>
						</a>
						<a v-can="'CONTACT_MANAGEMENT_UPDATE'" href="javascript:void(0);" class="action-icon   " @click="showComment(props.rowData, props.rowIndex)">
							<i class="fas fa-comment "></i>
						</a>
						<!-- <a v-can="'CONTACT_MANAGEMENT_VIEW'" href="javascript:void(0);" class="action-icon   "  @click="detailLog(props.rowData, props.rowIndex)">
							<i class="fas fa-eye"></i>
						</a> -->


						<a v-can="'CONTACT_MANAGEMENT_DELETE'" href="javascript:void(0);" class="action-icon " @click="onAction('delete-item', props.rowData, props.rowIndex)">
							<i class="fas fa-trash-alt"></i>
						</a>
						<a v-if="props.rowData.status !== 2" v-can="'CONTACT_MANAGEMENT_SCHEDULE_ENABLE'" href="javascript:void(0);" class="action-icon  " @click="getData(props.rowData, props.rowIndex)">
							<i class="far fa-calendar-alt"></i>
						</a>
					</div>
				</template>
			</vuetable>
		</div>
		<!-- end table -->

		<!-- pagination -->
		<div class="row">
			<div class="col-sm-12 col-md-5">
				<div class="row no-gutters">
					<!-- <div class="col-sm-12"> -->

					<div class="col-lg-3">
						<div class="form-group float-lg-left mx-1">
							<select v-model="perPage" class="form-control form-control-sm" @change="onChangeTableLength">
								<option v-for="opt in lengthMenu" :key="opt" :value="opt">{{
                  opt
                }}</option>
							</select>
						</div>
					</div>
					<div class="col-md-8">
						<vuetable-pagination-info ref="paginationInfo" class="
                justify-content-center justify-content-lg-start
                text-center text-lg-left
              "></vuetable-pagination-info>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-7">
				<vuetable-pagination ref="pagination" :css="css.pagination" class="
            pagination pagination-rounded
            justify-content-center justify-content-lg-end
          " @vuetable-pagination:change-page="onChangePage">
				</vuetable-pagination>
			</div>
		</div>
		<!-- end pagination -->
	</div>
</template>
<script>
	import Vuetable from 'vuetable-2/src/components/Vuetable'
	import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
	import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
	import {
		mapActions,
		mapState
	} from 'vuex'
	import moment from 'moment'
	import Swal from 'sweetalert2'
	import VuePerfectScrollbar from 'vue-perfect-scrollbar'
	import datetime from 'vuejs-datetimepicker'
	import axios from 'axios'

	import VueSimpleSuggest from 'vue-simple-suggest'
	import 'vue-simple-suggest/dist/styles.css' // Optional CSS


	var url = '/customer'
	// var status = false

	for (
		let i = 0; i < JSON.parse(localStorage.getItem('userPermissions')).length; i++
	) {
		
		if (
			JSON.parse(localStorage.getItem('userPermissions'))[i] ===
			'CRM_ADMIN_ENABLE'
		) {
			url = '/customer?admin=true'
		}
	}
	// console.log(status, 'status')
	// console.log(url, 'url')

	export default {
		components: {
			Vuetable,
			VuetablePagination,
			VuetablePaginationInfo,
			VuePerfectScrollbar,
			datetime,
			VueSimpleSuggest
		},
		props: {
			showActions: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				userName: '',
				moreParams: {},
				filterText: '',
				deleteData: {},
				search: '',
				httpOptions: {
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('access_token'),
					},
				},
				isLoadingTable: false,
				// apiUrl: this.$store.state.rootApi + '/customer/list',
				apiUrl: this.$store.state.rootApi + url,
				urlCi: process.env.VUE_APP_CI_URL,
				items: [{
						text: 'Master',
						href: '/',
					},
					{
						text: 'ReservationRoom',
						href: '/ReservationRoom',
					},
					{
						text: 'List',
						active: true,
					},
				],
				lengthMenu: [5, 10, 25, 50, 100],
				perPage: 5,
				fields: [
					// {
					// 	name: 'name',
					// 	title: 'Name',
					// 	// sortField: 'name',
					// },
					{
						name: 'name',
						title: 'Name',
						titleClass: 'text-center',
						dataClass: 'text-center',
						// width: '150px',
					},
					{
						name: 'mobile',
						title: 'Mobile',
						// sortField: 'mobile',
					},
					{
						name: 'email',
						title: 'Email',
						// sortField: 'email',
					},
					{
						name: 'address',
						title: 'Address',
						// sortField: 'address',
					},
					{
						name: 'status',
						title: 'Status',
						// sortField: 'status',
						formatter(value) {
							if (value === 0) {
								return 'Leads'
							} else if (value === 1) {
								return 'Visit'
							} else if (value === 2) {
								return 'Approved'
							} else if (value === 3) {
								return 'Prospect'
							} else if (value === 4) {
								return 'Reject'
							} else {
								return 'Unknown'
							}
						},
					},
					{
						name: 'name_user',
						title: 'Marketing',
						// sortField: 'created_by',
					},
					{
						name: 'created_at',
						title: 'Created At',
						titleClass: 'text-center',
						dataClass: 'text-center',
						width: '150px',
					},
					{
						name: 'updated_at',
						title: 'Updated At',
						titleClass: 'text-center',
						dataClass: 'text-center',
						width: '150px',
					},
					{
						name: 'actions',
						title: 'Actions',
						titleClass: 'text-left',
						dataClass: 'text-left',
						width: '170px',
					},
				],
				css: {
					pagination: {
						activeClass: 'btn-primary text-white',
						pageClass: 'btn btn-sm ',
						linkClass: 'btn btn-sm',
						icons: {
							prev: 'fas fa-arrow-left',
							next: 'fas fa-arrow-right',
						},
					},
				},
				autoCompleteStyle : {
					vueSimpleSuggest: "position-relative",
					inputWrapper: "",
					defaultInput : "form-control form-control-sm",
					suggestions: "position-absolute list-group z-1000",
					suggestItem: "list-group-item"
				},
				user: [],
				dataHistoryCustomer: {},
				dataNewCustomer: {
					status: 0,
				},
				formClose: false,
				remark: '',
				komentar: '',
				status: null,
				reason: null,
				idReason: null,
				isiReason: null,
				getList: [
					// {
					// 	id: 1,
					// 	name: 'Vue.js'
					// },
					// {
					// 	id: 2,
					// 	name: 'React.js'
					// },
					// {
					// 	id: 3,
					// 	name: 'Angular.js'
					// }
				],
				listStatus: [{
						id: 0,
						name: 'Leads',
					},
					{
						id: 1,
						name: 'Visit',
					},
					{
						id: 2,
						name: 'Approved',
					},
					{
						id: 3,
						// name: 'Closing'
						name: 'Prospect',
					},
					{
						id: 4,
						// name: 'Closing'
						name: 'Reject',
					},
				],
				isLoading: false,
				historyStatus: {
					height: 150,
				},
				plugins: ['code'],
				toolbars: 'undo redo styleselect bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent',
				trustMeter: 0,
				assignee: [],
				listAssigner: [],
				idUser: localStorage.getItem('session_user'),
				crmAdminEnabled: JSON.parse(localStorage.getItem('userPermissions')).includes('CRM_ADMIN_ENABLE')
			}
		},
		computed: {
			...mapState('customer', ['dataCustomer', 'listCustomer']),
			...mapState('customerHistory', ['dataCustomerHistory']),
			...mapState('user', ['dataUser', 'listUser']),
			...mapState('masterReason', ['listReason', 'dataReason']),

			resultQuery() {
				if (this.search) {
					return this.listCustomer.filter(item => {
						return this.search
							.toLowerCase()
							.split(" ")
							.every(v => item.name.toLowerCase().includes(v));
					});
				} else {
					return this.listCustomer;
				}
			},

		},

		mounted() {
			this.getListReason()
			this.getUser()
			this.userName = localStorage.getItem('name')
			
		},
		methods: {
			...mapActions('customer', [
				'deleteCustomer',
				'getCustomer',
				'updateCustomer',
				'saveCustomer',
				'getListCustomer'
			]),
			...mapActions('customerHistory', [
				'saveCustomerHistory',
				'getCustomerHistory',
			]),
			...mapActions('user', ['getUsername', 'getListUser']),
			...mapActions('masterReason', ['getListReason', 'getReason']),
			format_date(value) {
				if (value) {
					return moment(String(value)).format('DD  MMM YYYY, h:mm:ss a')
				}
			},
			format_date2(value) {
				if (value) {
					return moment(String(value)).format('DD  MMM YYYY')
				}
			},
			getUser() {
				try {
					this.getListUser()
						.then((res) => {
							console.log(res, 'res user')
							// this.user_id = res.result[1].id
						})
						.catch((err) => {
							console.log(err)
						})
				} catch (error) {
					console.log(error)
				}
			},
			changeStatus(value) {
				this.dataCustomer.value_status = value
				this.dataCustomer.status = value.id
				this.status = value.name
				console.log('status', this.status)
			},
			changeReason(value) {
				this.reason = value
				this.dataCustomerHistory.id_reason = value.id
				this.idReason = value.id
				console.log(this.dataCustomerHistory.id_reason, 'id reason')
			},
			detailLog: function (rowData, rowIndex) {
				console.log(rowData.id)
				this.$modal.show('logCustomer')
				this.getCustomer(rowData.id)
					.then((res) => {
						console.log(this.dataCustomer)
					})
					.catch((err) => {
						console.log(err)
						this.errorAlert()
					})
				this.getCustomerHistory(rowData.id)
					.then((res) => {
						console.log(res, 'respon customer history')
						for (let i = 0; i < this.dataCustomerHistory.length; i++) {
							this.getReason(this.dataCustomerHistory[i].id_reason)
								.then((p) => {
									this.isiReason = this.dataReason.reason
								})
								.catch((err) => {
									console.log(err)
								})

							// this.getUsername(this.dataCustomerHistory[i].created_by)
							// 	.then((resUser) => {
							// 		var usernameUser = this.dataUser.username_user
							// 		var username = usernameUser.match(/[a-zA-Z]+/g)
							// 		this.dataCustomerHistory[i].created_by = username[0]
							// 		console.log(username[0], 'username')
							// 	})
							// 	.catch((err) => {
							// 		console.log(err)
							// 	})
							// this.dataCustomerHistory[i].created_by = 
						}
					})
					.catch((err) => {
						console.log(err)
						this.errorAlert()
					})
			},
			editShow(rowData, rowIndex) {
				this.$modal.show('editCustomer')
				this.getCustomer(rowData.id)
					.then((res) => {
						if(this.dataCustomer.customer_history !== null && this.dataCustomer.customer_history !== undefined) {
							this.trustMeter = this.dataCustomer.customer_history.trust_meter
							this.komentar = this.dataCustomer.customer_history.remark
							this.assignee = []
							this.dataCustomer.customer_assignee.forEach(element => {
								this.assignee.push(element.id_user)
							});
						}
					})
					.catch((err) => {
						console.log(err)
						this.errorAlert()
					})
			},
			showComment(rowData, rowIndex) {
				this.$modal.show('commentCustomer')
				this.getCustomer(rowData.id)
					.then((res) => {

					})
					.catch((err) => {
						console.log(err)
						this.errorAlert()
					})
			},
			getData(rowData, rowIndex) {
				this.$modal.show('scheduleCustomer')
				this.getCustomer(rowData.id)
					.then((res) => {
						for (let i = 0; i < this.listStatus.length; i++) {
							if (i === this.dataCustomer.status) {
								this.status = this.listStatus[i].name
							}
						}
					})
					.catch((err) => {
						console.log(err)
						this.errorAlert()
					})
			},
			test() {
				this.getList = []
				axios.get(url).then((response) => {
					// console.log(response.data, 'data customer')
					for (let i = 0; i < response.data.length; i++) {
						const element = response.data[i];
						this.getList.push({
							id: element.id,
							name: element.name + ' *customer already exists',
							customer_history: element.customer_history,
							disabled: true
						})
					}

				}, () => {
					console.log("gagal")
				});
			},
			getCustomerList() {
				
				return this.getList
				
			},

			doFilter() {
				this.onFilterSet(this.filterText)
			},
			/**
			 * Table Length Change
			 *
			 * @return  {[type]}  [return description]
			 */
			onChangeTableLength() {
				// this.$refs.vuetable.reload()
				this.$nextTick(() => this.$refs.vuetable.refresh())
			},
			/**
			 * Pagination page change
			 */
			onChangePage(page) {
				this.$refs.vuetable.changePage(page)
			},
			/**
			 * Pagination info and pagination show
			 */
			onPaginationData(paginationData) {
				this.$refs.pagination.setPaginationData(paginationData)
				this.$refs.paginationInfo.setPaginationData(paginationData)
			},
			/**
			 * [onFilterSet description]
			 *
			 * @param   {[type]}  filterText  [filterText description]
			 *
			 * @return  {[type]}              [return description]
			 */
			onFilterSet(filterText) {
				this.moreParams = {
					filter: filterText,
				}
				this.$nextTick(() => this.$refs.vuetable.refresh())
			},
			/**
			 * Show Table Loading
			 *
			 * @return  {[type]}  [return description]
			 */
			loadingTable() {
				this.isLoadingTable = true
			},

			/**
			 * Hide Loading if Success
			 *
			 * @return  {[type]}  [return description]
			 */
			loadTableSuccess() {
				this.isLoadingTable = false
			},
			/**
			 * Hide Loading if Failed
			 *
			 * @return  {[type]}  [return description]
			 */
			loadTableFailed(error) {
				console.log(error)

				this.isLoadingTable = false
				this.$swal({
					title: 'Terjadi Kesalahan',
					type: 'error',
					timer: '2000',
					toast: true,
					position: 'bottom-end',
				})
			},
			showFormInput() {
				this.trustMeter = '0'
				this.$modal.show('inputCustomer')
				this.test()
			},
			hideFormInput() {
				this.isLoadingTable = false
				this.$modal.hide('inputCustomer')
				this.dataNewCustomer = {}
			},
			hideFormEdit() {
				this.isLoadingTable = false
				this.$modal.hide('editCustomer')
				this.dataCustomer = {}
				this.$refs.vuetable.refresh()
			},

			hideFormKomentar() {
				this.isLoadingTable = false
				this.$modal.hide('commentCustomer')
				this.dataCustomer = {}
				this.$refs.vuetable.refresh()

			},
			hideFormSchedule() {
				this.isLoadingTable = false
				this.$modal.hide('scheduleCustomer')
				this.dataCustomer = {}
				this.$refs.vuetable.refresh()
			},
			onAction(action, rowData, rowIndex) {
				if (action === 'edit-item') {
					this.$modal.show('scheduleCustomer')
					this.getCustomer(rowData.id)
						.then((res) => {
							this.dataCustomer = res
							// console.log('isi dataCustomer.status', this.dataCustomer.status)
							// if (this.dataCustomer.status === 0) {
							// 	this.dataCustomer.status = 1
							// } else if (this.dataCustomer.status === 1) {
							// 	this.dataCustomer.status = 2
							// }
							// console.log(this.dataCustomer)
						})
						.catch((err) => {
							console.log(err)
							this.errorAlert()
						})
				} else if (action === 'view-item') {
					this.$router.push({
						name: 'ContactManagement View',
						params: {
							id: rowData.id,
						},
					})
				} else if (action === 'delete-item') {
					this.showConfirmDelete(rowData.id)
				}
			},

			showConfirmDelete(id) {
				this.deleteData.id = id
				let self = this
				// this.$swal({
				// 	title: 'Are you sure?',
				// 	text: 'You will not be able to recover this data!',
				// 	type: 'warning',
				// 	showCancelButton: true,
				// 	confirmButtonText: 'Yes, delete it!',
				// 	cancelButtonText: 'Cancel',
				// }).then((result) => {
				// 	if (result.value) {
				// 		this.$swal('Deleted!', 'Your data has been deleted.', 'error')
				// 		self.onDelete()
				// 	} else if (result.dismiss) {
				// 		this.$swal('Cancelled', 'Your data is safe :)', 'error')
				// 	}
				// })
				Swal.fire({
					title: 'Are you sure?',
					text: 'You will not be able to recover this data!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					cancelButtonColor: '#C1C1C1',
					reverseButtons: true,
					confirmButtonText: 'Yes, delete it!',
				}).then((result) => {
					if (result.isConfirmed) {
						self.onDelete()
						Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
					}
				})
			},
			/**
			 * Delete Brand
			 *
			 * @return  {[type]}  [return description]
			 */
			onDelete() {
				// this.isLoadingTable = true
				this.deleteCustomer(this.deleteData.id)
					.then((res) => {
						this.successAlert()
						// this.isLoadingTable = false;
						this.$refs.vuetable.refresh()
					})
					.catch((err) => {
						this.errorAlert()
						console.log(err.response.message)

						// this.isLoadingTable = false;
					})
			},

			formatTgl() {
				if (this.dataCustomer.schedule_time) {
					return moment(String(this.dataCustomer.schedule_time)).format(
						'MM-DD-YYYY hh:mm:ss'
					)
				}
				// console.log(this.dataCustomer.schedule_time)
			},
			addCommentar() {
				let headers = {
					'Content-Type': 'application/json'
					// Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				}

				console.log(this.komentar, 'namanya')
				this.dataHistoryCustomer.id_customer = this.dataCustomer.id
				this.dataHistoryCustomer.status = this.dataCustomer.status
				this.dataHistoryCustomer.remark = this.komentar
				this.dataHistoryCustomer.is_commentar = 1
				this.dataHistoryCustomer.trust_meter = this.trustMeter
				this.saveCustomerHistory({
						payload: this.dataHistoryCustomer,
						headers: headers,
					})
					.then((res) => {
						// console.log(res, 'update customer history')

						// this.dataNewCustomer = {}
						this.dataCustomer.schedule_time = null
						this.komentar = null
						this.$modal.hide('commentCustomer')
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							},
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully',
						})
					})
					.catch((err) => {
						console.log(err)
					})
			},
			submitData() {

				console.log(this.idReason , 'id reson')
				console.log(this.dataCustomer.schedule_time, 'schedule time')

				let headers = {
					'Content-Type': 'application/json',
				}

				// let tgl = moment(String(this.dataCustomer.schedule_time)).format('YYYY-MM-DD hh:mm:ss')
				let tgl = this.dataCustomer.schedule_time
				this.dataHistoryCustomer.id_customer = this.dataCustomer.id
				this.dataHistoryCustomer.status = this.dataCustomer.status
				this.dataHistoryCustomer.remark = this.remark
				this.dataHistoryCustomer.id_reason = this.idReason
				
				// console.log('tgl', tgl)
				if (this.dataCustomer.status === 1) {
					if (tgl == null) {
						// console.log('tgl kosong')
						this.errorAlert('Date schedule cannot be null!')
					} else {
						this.dataHistoryCustomer.schedule_time = tgl
						this.updateCustomer({
								payload: this.dataCustomer,
								id: this.dataCustomer.id,
							})
							.then((res) => {
								console.log(res)
								// input historoy
								this.saveCustomerHistory({
										payload: this.dataHistoryCustomer,
										headers: headers,
									})
									.then((res) => {
										console.log(res)
										this.$refs.vuetable.refresh()
									})
									.catch((err) => {
										console.log(err)
									})
							})
							.catch((err) => {
								console.log(err)
								this.errorAlert('failed update data')
							})

						this.successAlert('Successfully')
						this.dataCustomer.schedule_time = null
						this.remark = null
						this.$modal.hide('scheduleCustomer')
					}
				} else if(this.dataCustomer.status === 4) {
					if(this.idReason == null ){
						this.errorAlert('Date schedule cannot be null!')
					}else{
						// update customer
					this.updateCustomer({
							payload: this.dataCustomer,
							id: this.dataCustomer.id,
						})
						.then((res) => {
							console.log(res)
							// input historoy
							this.saveCustomerHistory({
									payload: this.dataHistoryCustomer,
									headers: headers,
								})
								.then((res) => {
									console.log(res)
									this.$refs.vuetable.refresh()
									if (this.dataCustomer.status === 2) {
										window.location.href =
											this.urlCi +
											'/admin/master_customer/edit/' +
											this.dataCustomer.id
									}
								})
								.catch((err) => {
									console.log(err)
								})
						})
						.catch((err) => {
							console.log(err)
							this.errorAlert('failed update data')
						})

					this.successAlert('Successfully')
					this.dataCustomer.schedule_time = null
					this.remark = null
					this.$modal.hide('scheduleCustomer')
					}
					
				}else{
					// update customer
					this.updateCustomer({
							payload: this.dataCustomer,
							id: this.dataCustomer.id,
						})
						.then((res) => {
							console.log(res)
							// input historoy
							this.saveCustomerHistory({
									payload: this.dataHistoryCustomer,
									headers: headers,
								})
								.then((res) => {
									console.log(res)
									this.$refs.vuetable.refresh()
									if (this.dataCustomer.status === 2) {
										window.location.href =
											this.urlCi +
											'/admin/master_customer/edit/' +
											this.dataCustomer.id
									}
								})
								.catch((err) => {
									console.log(err)
								})
						})
						.catch((err) => {
							console.log(err)
							this.errorAlert('failed update data')
						})

					this.successAlert('Successfully')
					this.dataCustomer.schedule_time = null
					this.remark = null
					this.$modal.hide('scheduleCustomer')
				}
			},

			updateDate() {
				this.dataCustomer.trust_meter = this.trustMeter
				this.dataCustomer.notes = this.komentar
				this.dataCustomer.assignee = this.assignee

				// update customer
				this.updateCustomer({
						payload: this.dataCustomer,
						id: this.dataCustomer.id,
					})
					.then((res) => {
						console.log(res)
						this.$refs.vuetable.refresh()
					})
					.catch((err) => {
						console.log(err)
						this.errorAlert('failed update data')
					})

				// this.successAlert('Successfully')
				const Toast = Swal.mixin({
					toast: true,
					position: 'bottom-end',
					showConfirmButton: false,
					timer: 2000,
					timerProgressBar: true,
					onOpen: (toast) => {
						toast.addEventListener('mouseenter', Swal.stopTimer)
						toast.addEventListener('mouseleave', Swal.resumeTimer)
					},
				})

				Toast.fire({
					icon: 'success',
					title: 'successfully',
				})
				this.$modal.hide('editCustomer')
			},

			inputData() {
				// check if data already exists by name
				axios.get('/customerName?name=' + this.dataNewCustomer.name).then((response) => {
					console.log(response.data, 'data customer')
					if(response.data.length === 0){
						this.dataNewCustomer.status = 0
						this.dataNewCustomer.trust_meter = this.trustMeter
						this.dataNewCustomer.notes = this.komentar
						this.dataNewCustomer.assignee = this.assignee
						this.saveCustomer({
								payload: this.dataNewCustomer,
							})
							.then((res) => {
								const Toast = Swal.mixin({
									toast: true,
									position: 'bottom-end',
									showConfirmButton: false,
									timer: 2000,
									timerProgressBar: true,
									onOpen: (toast) => {
										toast.addEventListener('mouseenter', Swal.stopTimer)
										toast.addEventListener('mouseleave', Swal.resumeTimer)
									},
								})

								Toast.fire({
									icon: 'success',
									title: 'successfully',
								})

								this.$modal.hide('inputCustomer')
								console.log(res)
								this.$refs.vuetable.refresh()
								this.dataNewCustomer = {}
							})
							.catch((err) => {
								console.log(err)
								this.errorAlert('Failed save data')
							})
					}else{
						Swal.fire('Warning!', 'Customer Already Exists.', 'warning')
					}
					
				}, () => {
					console.log("gagal")
				});
				
			},
			changeTrustMeter(trustMeter) {
				this.trustMeter = trustMeter
			},
			getLabel (item) {
				return item.name
			},
			updateItems (text) {
				console.log(text, 'this text')
				// yourGetItemsMethod(text).then( (response) => {
				// 	this.items = response
				// })
			},
			onSuggestSelect () {
				console.log('OKE')
			},
			onAutocompleteButtonClick(suggestion = '') {
				console.log(suggestion, 'this suggest')
				// this.$refs.suggest.autocompleteText(suggestion);
			}
		},
	}

</script>
